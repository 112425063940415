import React from "react";
import { graphql } from "gatsby";
import { path } from "ramda";
import AboutBox from "../components/About/AboutBox";
import Email from "../components/Email/Email";
import about from "../data/About";
import {
  UserWrapper,
  UserTitle,
  UserDescription,
  UserTopic,
  ComingSoon,
} from "../styles/indexStyle.js";
import Layout from "../components/layout";

const IndexPage = (props) => {
  const image = path(["data", "file", "childImageSharp", "fixed"], props);
  return (
    <Layout>
      <UserWrapper>
        <UserTitle>
          App <span>FEED</span>
        </UserTitle>
        <UserDescription>
          <div>
            <p>
              Powerful user data feeds driven by AI.
              <br /> Deliver what your users want every time.
            </p>
          </div>
          <ComingSoon>
            <UserTitle>
              <span>COMING</span> SOON
            </UserTitle>
          </ComingSoon>
        </UserDescription>
        <UserTopic>
          {about.map((item) => (
            <AboutBox key={item.id} info={item} />
          ))}
        </UserTopic>
      </UserWrapper>
      <Email image={image} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "email-envelope-mail.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
