import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import styled from "styled-components";
import Img from "gatsby-image";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 345px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 3% 2%;
  width: 40%;
  margin: auto;
  margin-bottom: 50px;
  transition: box-shadow 0.5s ease-out;
  :last-of-type {
    margin-right: 0;
  }
  @media (max-width: 700px) {
    width: 100%;
    min-height: auto;
    padding: 5%;
  }
`;

export const EmailHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  p {
    max-width: 95%;
    font-weight: 300;
    font-size: 26px;
    line-height: 1.3;
    margin-bottom: 5px;
  }
  @media (max-width: 700px) {
    p {
      max-width: 100%;
      font-size: 19px;
    }
  }
`;

const StyledInput = styled.input`
  width: 90%;
  margin-bottom: 10px;
`;

const StyledForm = styled.form`
  width: 95%;
  margin-left: 30px;
  @media (max-width: 700px) {
    margin-left: 20px;
  }
`;

const StyledButton = styled.button`
  background-color: #7fa1e8;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  border-spacing: 0;
  width: 90%;
  height: 35px;
`;

const ThankYou = styled.h2`
  text-align: center;
  margin-top: 25px;
`;

const Label = styled.label`
  display: block;
`;

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default (props) => {
  const [msg, setMsg] = useState(null);
  const [form, setForm] = useState({});

  const onFinish = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "app-feed", ...form }),
    })
      .then(() => {
        console.log("Form submission success", form);
        setMsg("Thank You");
      })
      .catch((error) => {
        console.error("Form submission error:", error);
      });
    e.preventDefault();
  };

  return (
    <Wrapper>
      <EmailHeader>
        <Img fixed={props.image} />
        <p>More coming soon</p>
        <span>Complete the form below for updates</span>
      </EmailHeader>
      <StyledForm
        name="app-feed"
        onSubmit={onFinish}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/appfeed"
      >
        <Label htmlFor="email">Email</Label>
        <StyledInput
          name="email"
          onChange={(e) => setForm({ ...form, email: e.currentTarget.value })}
        />
        <Label htmlFor="firstname">FirstName</Label>
        <StyledInput
          name="firstname"
          onChange={(e) =>
            setForm({ ...form, firstname: e.currentTarget.value })
          }
        />
        <Label htmlFor="lastname">Lastname</Label>
        <StyledInput
          name="lastname"
          onChange={(e) =>
            setForm({ ...form, lastname: e.currentTarget.value })
          }
        />
        <Label>
          <StyledButton type="primary" htmlType="submit">
            Submit
          </StyledButton>
          <ThankYou>{msg}</ThankYou>
        </Label>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="app-feed" />
      </StyledForm>
    </Wrapper>
  );
};
