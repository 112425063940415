export default [
  {
    id: 1,
    title: "User Models",
    description:
      "Well established prebuilt user models allow you to making accurate predictions from day one.",
  },
  {
    id: 2,
    title: "Recommendations",
    description:
      "Powerful recommendations engine provides user with a unique experience that drive engagement.",
  },
  {
    id: 3,
    title: "Machine Learning",
    description:
      "Powerful Machine Learning algorithms are always learning so you stay up to date with your users.",
  },
];
